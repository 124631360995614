import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Link, PageProps } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import Section from "../components/Section";
import SEO from "../components/Seo";
import SignUpFormControl from "../components/SignUpFormControl";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignItems: "center",
            textAlign: "center",
        },
        finePrint: {
            fontSize: "0.875rem",
            margin: 0,
        },
    })
);

const title = "Join me";
const description = "Get full articles delivered to your inbox.";

const SignUp: React.FC<PageProps> = (props) => {
    const classes = useStyles();

    return (
        <Layout>
            <SEO title={title} description={description} path={props.path} />
            <Section content first last>
                <PageTitle title={title} />
                <div className={classes.root}>
                    <p>{description}</p>
                    <SignUpFormControl />
                    <p className={classes.finePrint}>
                        Form is protected by reCAPTCHA. Google's{" "}
                        <a href="https://policies.google.com/privacy">
                            Privacy
                        </a>{" "}
                        and{" "}
                        <a href="https://policies.google.com/terms">Terms</a>{" "}
                        apply.
                    </p>
                </div>
            </Section>
        </Layout>
    );
};

export default SignUp;
